import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="comingsoon-body">
      <div className="overlay"></div>
      <div className="stars" aria-hidden="true"></div>
      <div className="starts2" aria-hidden="true"></div>
      <div className="stars3" aria-hidden="true"></div>
      <main className="main">
        <section className="contact">
          <h1 className="title">Coming Soooon................</h1>
          <h2 className="sub-title">       AK Blogs        </h2>
          {/* <h2 className="sub-title">Site Under Construction</h2> */}
        </section>
      </main>
    </div>
  );
}

export default App;
